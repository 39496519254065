import * as React from "react"

import Layout from "../components/layout"
import P404 from "../components/pages/P404"


const NotFoundPage = () => {

  return (
    <Layout>
      <P404 />
    </Layout>
  )
}

export default NotFoundPage
