import * as React from "react"

import * as styles from "./P404.module.css"
import Header from "../header"

import { StaticImage } from "gatsby-plugin-image"
import { Link, useIntl } from "gatsby-plugin-intl-v4"
import LogoCutSVG from '../../assets/svg/Logo_Mansfield_Footer.svg'

const P404 = () => {

    const intl = useIntl();

    return (
        <div className={`pageContainer ${styles.bg}`}>
            <Header hidePlaceholder={true} />
            <StaticImage
                src='../../images/homepage/Mansfield_016_L.jpg'
                loading="eager"
                placeholder="blurred"
                layout="fullWidth"
                quality={75}
                formats={["auto", "webp", "avif"]}
                alt={intl.formatMessage({ id: 'alts.livingRoom' })}
                style={{
                    maxHeight: "50vh"
                }}
            />
            <div className={styles.titles}>
                <h1>{intl.formatMessage({ id: '404.title' })}</h1>
                <h3><Link to="/" >{intl.formatMessage({ id: '404.backHome' })}</Link></h3>
            </div>
            <div className={styles.cutLogo}>
                <LogoCutSVG width="100%" height="100%" />
            </div>
        </div>
    )

}

export default P404
